import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { withTranslation } from 'react-i18next'
import makeAnimated from "react-select/animated"

import withRouter from '../../Common/withRouter'

function TestsRow(props) {
    const animatedComponents = makeAnimated()

    const [range, setRange] = useState(props.row.testRange || '')
    const [result, setResult] = useState(props.row.testResults || '')
    const [ selectedRemark, setSelectedRemark ] = useState(
        props.labRemarkOptions.find((element) => element.value === props.row.testRemark) || null
    )
    const [ selectedSample, setSelectedSample ] = useState(
        props.labSampleOptions.find((element) => element.value === props.row.sampleType) || null
    )

    useEffect(() => {
        let row = {
            id: props.row.id ? props.row.id : null,
            productName: props.row && props.row.productName || '',
            sampleType: (selectedSample && selectedSample.value) || null,
            testRemark: (selectedRemark && selectedRemark.value) || null,
            testResults: result,
            testRange: range
        }

        props.updateRow(row, props.index);
    },[selectedSample, selectedRemark, range, result, props.row.productName])

  return (
    < >
        <td>
            {props.row && props.row.productName || ''}
        </td>
        <td>
            <Select
                name='status'
                id='status-field'
                value={selectedSample}
                isMulti={false}
                isClearable={false}
                onChange={(e) => {
                    setSelectedSample(e);
                }}
                options={props.labSampleOptions}
                closeMenuOnSelect={true}
                components={animatedComponents}
            />
        </td>
        <td>
            <textarea
                className="form-control"
                rows={3}
                placeholder={props.t("type_here")}
                value={result}
                onChange={(e) => {setResult(e.target.value) }}
            />
        </td>
        <td>
            <textarea
                className="form-control"
                rows={3}
                placeholder={props.t("type_here")}
                value={range}
                onChange={(e) => {setRange(e.target.value) }}
            />
        </td>
        <td>
            <Select
                name='status'
                id='status-field'
                value={selectedRemark}
                isMulti={false}
                isClearable={false}
                onChange={(e) => {
                    setSelectedRemark(e);
                }}
                options={props.labRemarkOptions}
                closeMenuOnSelect={true}
                components={animatedComponents}
            />
        </td>
    </>
  )
}

export default withRouter(withTranslation()(TestsRow))
