import { createSlice } from "@reduxjs/toolkit"
import { 
    addRole, deleteRole, getPermission, getPermissons, updatePermission,
    getRole, getRoles, getAllRoles, updateRole, searchRole,
    getProfile, getUsers, loadUser, addUser, updateUser, deleteUser, searchUser,
    getDoctors, getDoctorTitle, getDoctorInfo, addDoctor, updateDoctor, deleteDoctor, searchDoctor,
    getWards, loadWards, addWard, updateWard, deleteWard, searchWard,
    getBeds, addBed, updateBed, deleteBed, searchBed,
    getCategories, getAllCategories, getCategory, createCategory, updateCategory, deleteCategory, searchCategory,
    getClassesAccounts, getParentClassesAccount, getTypesClassesAccount, getSubClassAccount, getParentSubClassAccount,
    createClassAccount, getClassAccount, updateClassAccount, deleteClassAccount, searchClassAccount,
    getSuppliers, getAllSuppliers, getSupplier, createSupplier, updateSupplier,deleteSupplier, searchSupplier,
    getProducts, getProductsAndSearch, getProductsAndSearchStock, getProduct, createProduct, updateProduct, searchProduct,deleteProduct,
    getSuppliersOrder, getSupplierOrder, addSupplierOrder, updateSupplierOrder, confirmSupplierOrder, deleteSupplierOrderItem,
    getWarehouseStock,
    getProductsDiscount, getProductDiscount, createProductDiscount, updateProductDiscount, deleteProductDiscount,
    getTransfertOrders, getTransfertOrder, createTransfertOrder, updateTransfertOrder, confirmTransfertOrder,
    getPharmacyStock, getPharmacyProductsAndSearch,
    getSales, getSale, createSale,
    getAssociatedService,
    getInvoices, getPatientInvoice, getInvoiceStatus, getPaymentMode,
    getFinancialYears, getFinancialYear, createFinancialYear, closeFinancialYear, openFinancialYear,
    makePayment,
    getPatientAccounts, getPatientAccount, getPatientAccountHistory, createPatientAccount, makeDeposit,
    getPayments, getPayment, confirmPayment,
    getDeposits,
    createCashDesk, openCashDesk, closeCashDesk, getCloseCashDesks, getCashDesks, checkOpenCashdesk, getAmountsCollectedGroupBySources, updateCashDesk,
    getCashJournal,
    getBankAccounts, getAllBankAccounts, createBankAccount, updateBankAccount, deleteBankAccount,
    getBudgeting, createBudgeting,
    getRequisitions, getRequisition, getRequisitionStatus, createRequisition, approveRequisition, rejectRequisition, payRequisition, cancelRequisition, updateRequisition,
    getCashDrops, createCashDrop,
    getOtherRevenues, createOtherRevenue,
    getPrintSetting, savePrintSetting,
    getAccountSummary, getAccountSummaryDetails,
} from "./thunk";

export const initialState = {

    permissions: [],
    permission: {},
    roles: [],
    allroles: [],
    role: {},
    users: [],
    user: {},
    doctors: [],
    doctorTitles: [],
    doctor: {},
    wards: [],
    allwards: [],
    ward: {},
    beds: [],
    bed: {},
    categories: [],
    all_categories: [],
    category:{},
    classesAccount: [],
    parentClassesAccount: [],
    parentSubClassesAccount: [],
    classAccount: {},
    typeClassesAccount: [],
    subClassAccount: [],
    suppliers: [],
    allSuppliers: [],
    supplier: {},
    products: [],
    product: {},
    productsList: [],
    productsListStock: [],
    suppliersOrder: [],
    supplierOrder: {},
    stockReports: [],
    phStockReports: [],
    productsDiscount: [],
    productDiscount: {},
    transfertOrders: [],
    transfertOrder: {},
    pharmacyProduct: [],
    sales: [],
    billDischarge: [],
    sale: {},
    associatedService: [],
    saleInvoices: [],
    paymentModes: [],
    financialYears: [],
    financialYear: {},
    patientAccounts: [],
    patientAccount: {},
    payments: [],
    cashJournal: [],
    budgetings: [],
    budgeting: {},
    payment: {},
    deposits: [],
    invoiceStatus: [],
    patientInvoices: [],
    patientAccountHistory: [],
    printSettings: {},
    accountSummary: [],
    summaryDetail: {},
    cashDeskOpen: {},


    category_success: null,
    doctor_success: null,
    user_success: null,
    role_success: null,
    ward_success: null,
    bed_success: null
}

const SettingsSlice = createSlice({
    name: "settings",
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        /**
         * Permissions
         */
        builder.addCase(getPermissons.fulfilled, (state, action) => {
            state.permissions = action.payload.data
        })
        builder.addCase(getPermissons.rejected, (state, action) => {
            state.error = action.payload.error || null
        })

        builder.addCase(getPermission.fulfilled, (state, action) => {
            state.permission = action.payload.data
        })
        builder.addCase(getPermission.rejected, (state, action) => {
            state.error = action.error.error || null
        })

        builder.addCase(updatePermission.fulfilled, (state, action) => {
            state.permissions = state.permissions.map(permission =>
                permission.id.toString() === action.payload.data.id.toString()
                ? {...permission, ...action.payload.data }
                : permission
            )
        })
        builder.addCase(updatePermission.rejected, (state, action) => {
            state.error = action.error.error || null
        })

        /**
         *  Roles
         */
        builder.addCase(getRoles.fulfilled, (state, action) => {
            state.roles = action.payload.data
        })
        builder.addCase(getRoles.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // get all
        builder.addCase(getAllRoles.fulfilled, (state, action) => {
            state.allroles = action.payload.data
        })
        builder.addCase(getAllRoles.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // Get single
        builder.addCase(getRole.fulfilled, (state, action) => {
            state.role = action.payload.data
        })
        builder.addCase(getRole.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // Create
        builder.addCase(addRole.fulfilled, (state, action) => {
            if (state.roles.items.length < 10) {
                state.roles.total_items = state.roles.total_items+1
                state.roles.items.push(action.payload.data)
            }
            state.error = null
            state.role = action.payload.data
            state.role_success = true
        })
        builder.addCase(addRole.rejected, (state, action) => {
            state.error = action.error.message || null
            state.role_success = false
        })
        // Update
        builder.addCase(updateRole.fulfilled, (state, action) => {
            state.roles.items = state.roles.items.map(role =>
                role.id.toString() === action.payload.data.id.toString()
                ? { ...role, ...action.payload.data}
                : role
            )
            state.error = null
            state.role = action.payload.data
            state.role_success = true
        })
        builder.addCase(updateRole.rejected, (state, action) => {
            state.error = action.error.message || null
            state.role_success = false
        })
        // Delete
        builder.addCase(deleteRole.fulfilled, (state, action) => {
            state.roles.items = (state.roles.items || []).filter((role) => role.id.toString() !== action.meta.arg.id.toString())
            state.roles.total_items = state.roles.total_items-1
        })
        builder.addCase(deleteRole.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // search
        builder.addCase(searchRole.fulfilled, (state, action) => {
            state.roles = action.payload.data
        })
        builder.addCase(searchRole.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        /**
         *  Users
         */
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.profile = action.payload.data
        })
        builder.addCase(getProfile.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.users = action.payload.data
        })
        builder.addCase(getUsers.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // get single
        builder.addCase(loadUser.fulfilled, (state, action) => {
            state.user = action.payload.data
        })
        builder.addCase(loadUser.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // create
        builder.addCase(addUser.fulfilled, (state, action) => {
            if (state.users.items.length < 10) {
                state.users.total_items = state.users.total_items+1
                state.users.items.push(action.payload.data)
            }
            state.error = null
            state.user_success = true
            state.user = action.payload.data
        })
        builder.addCase(addUser.rejected, (state, action) => {
            state.error = action.error.message || null
            state.user_success = false
        })
        // Update
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.users.items = state.users.items.map(user =>
                user.id.toString() === action.payload.data.id.toString()
                ? { ...user, ...action.payload.data}
                : user
            )
            state.error = null
            state.user_success = true
            state.user = action.payload.data
        })
        builder.addCase(updateUser.rejected, (state, action) => {
            state.error = action.error.message || null
            state.user_success = false
        })
        // delete
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.users.items = (state.users.items || []).filter((user) => user.id.toString() !== action.meta.arg.toString())
            state.users.total_items = state.users.total_items-1

        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // search
        builder.addCase(searchUser.fulfilled, (state, action) => {
            state.users = action.payload.data
        })
        builder.addCase(searchUser.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        /*
        *  Doctors
        */
        builder.addCase(getDoctors.fulfilled, (state, action) => {
            state.doctors = action.payload.data
        })
        builder.addCase(getDoctors.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // get title
        builder.addCase(getDoctorTitle.fulfilled, (state, action) => {
            state.doctorTitles = action.payload.data
        })
        builder.addCase(getDoctorTitle.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // get one
        builder.addCase(getDoctorInfo.fulfilled, (state, action) => {
            state.doctor = action.payload.data
        })
        builder.addCase(getDoctorInfo.rejected, (state, action) => {
            state.error = action.error.message
        })// create
        builder.addCase(addDoctor.fulfilled, (state, action) => {
            if (state.doctors.items.length < 10) {
                state.doctors.total_items = state.users.total_items+1
                state.doctors.items.push(action.payload.data)
            }
            state.error = null
            state.doctor_success = true
            state.doctor = action.payload.data
        })
        builder.addCase(addDoctor.rejected, (state, action) => {
            state.error = action.error.message || null
            state.doctor_success = false
        })
        // update
        builder.addCase(updateDoctor.fulfilled, (state, action) => {
            // state.doctors.items = state.doctors.items.map(doctor =>
            //     doctor.id.toString() === action.payload.data.id.toString()
            //     ? { ...doctor, ...action.payload.data}
            //     : doctor
            // )
            state.error = null
            state.doctor_success = true
            state.doctor = action.payload.data
        })
        builder.addCase(updateDoctor.rejected, (state, action) => {
            state.error = action.error.message | ""
            state.doctor_success = false
        })
        // delete
        builder.addCase(deleteDoctor.fulfilled, (state, action) => {
            state.doctors.items = (state.doctors.items || []).filter((doctor) => doctor.id.toString() !== action.meta.arg.toString())
            state.success = true
        })
        builder.addCase(deleteDoctor.rejected, (state, action) => {
            state.error = action.error.message
        })
        // search
        builder.addCase(searchDoctor.fulfilled, (state, action) => {
            state.doctors = action.payload.data
        })
        builder.addCase(searchDoctor.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        /**
         *  Wards
         */
        builder.addCase(getWards.fulfilled, (state, action) => {
            state.wards = action.payload.data
        })
        builder.addCase(getWards.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // get single
        builder.addCase(loadWards.fulfilled, (state, action) => {
            state.allwards = action.payload.data
        })
        builder.addCase(loadWards.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // create
        builder.addCase(addWard.fulfilled, (state, action) => {
            if (state.wards.items.length < 10) {
                state.wards.total_items = state.wards.total_items+1
                state.wards.items.push(action.payload.data)
            }
            state.error = null
            state.ward_success = true
            state.ward = action.payload.data
        })
        builder.addCase(addWard.rejected, (state, action) => {
            state.error = action.error.message || null
            state.ward_success = false
        })
        // update
        builder.addCase(updateWard.fulfilled, (state, action) => {
            state.wards.items = state.wards.items.map(ward =>
                ward.id.toString() === action.payload.data.id.toString()
                ? { ...ward, ...action.payload.data}
                : ward
            )
            state.error = null
            state.ward_success = true
            state.ward = action.payload.data
        })
        builder.addCase(updateWard.rejected, (state, action) => {
            state.error = action.error.message || null
            state.ward_success = false
        })
        // delete
        builder.addCase(deleteWard.fulfilled, (state, action) => {
            state.wards.items = (state.wards.items || []).filter((ward) => ward.id.toString() !== action.meta.arg.toString())
            state.users.total_items = state.users.total_items-1

        })
        builder.addCase(deleteWard.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // search
        builder.addCase(searchWard.fulfilled, (state, action) => {
            state.wards = action.payload.data
        })
        builder.addCase(searchWard.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        /**
         *  Beds
         */
        builder.addCase(getBeds.fulfilled, (state, action) => {
            state.beds = action.payload.data
        })
        builder.addCase(getBeds.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // create
        builder.addCase(addBed.fulfilled, (state, action) => {
            if (state.beds.items.length < 10) {
                state.beds.total_items = state.beds.total_items+1
                state.beds.items.push(action.payload.data)
            }
            state.error = null
            state.bed_success = true
            state.bed = action.payload.data
        })
        builder.addCase(addBed.rejected, (state, action) => {
            state.error = action.error.message || null
            state.bed_success = false
        })
        // update
        builder.addCase(updateBed.fulfilled, (state, action) => {
            state.beds.items = state.beds.items.map(bed =>
                bed.id.toString() === action.payload.data.id.toString()
                ? { ...bed, ...action.payload.data}
                : bed
            )
            state.error = null
            state.bed_success = true
            state.bed = action.payload.data
        })
        builder.addCase(updateBed.rejected, (state, action) => {
            state.error = action.error.message || null
            state.bed_success = false
        })
        // delete
        builder.addCase(deleteBed.fulfilled, (state, action) => {
            state.beds.items = (state.beds.items || []).filter((bed) => bed.id.toString() !== action.meta.arg.toString())
            state.beds.total_items = state.beds.total_items-1

        })
        builder.addCase(deleteBed.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // search
        builder.addCase(searchBed.fulfilled, (state, action) => {
            state.beds = action.payload.data
        })
        builder.addCase(searchBed.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        // Categories
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.categories = action.payload.data
            state.category_success = false
            state.error = null
        })
        builder.addCase(getCategories.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getAllCategories.fulfilled, (state, action) => {
            state.all_categories = action.payload.data
            state.error = null
        })
        builder.addCase(getAllCategories.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getCategory.fulfilled, (state, action) => {
            state.category = action.payload.category
            state.category_success = false
            state.error = null
        })
        builder.addCase(getCategory.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(createCategory.fulfilled, (state, action) => {
            state.category = action.payload.category
            state.category_success = true
            state.error = null
        })
        builder.addCase(createCategory.rejected, (state, action) => {
            state.category_success = false
            state.error = action.error.message || null
        })

        builder.addCase(updateCategory.fulfilled, (state, action) => {
            state.category = action.payload.data
            state.category_success = true
            state.error = null
        })
        builder.addCase(updateCategory.rejected, (state, action) => {
            state.category_success = false
            state.error = action.error.message || null
        })

        builder.addCase(deleteCategory.fulfilled, (state, action) => {
            state.category_success = true
            state.error = null
        })
        builder.addCase(deleteCategory.rejected, (state, action) => {
            state.category_success = false
            state.error = action.error.message || null
        })

        builder.addCase(searchCategory.fulfilled, (state, action) => {
            state.categories = action.payload.data
            state.category_success = false
            state.error = null
        })
        builder.addCase(searchCategory.rejected, (state, action) => {
            state.error = action.error.message || null
        })


        // Classes Account
        builder.addCase(getClassesAccounts.fulfilled, (state, action) => {
            state.classesAccount = action.payload.data
            state.ClassAccountCreateSuccess = false
            state.ClassAccountUpdateSuccess = false
            state.ClassAccountDeleteSuccess = false
            state.error = null
        })
        builder.addCase(getClassesAccounts.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getParentClassesAccount.fulfilled, (state, action) => {
            state.parentClassesAccount = action.payload.data
            state.error = null
        })
        builder.addCase(getParentClassesAccount.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getParentSubClassAccount.fulfilled, (state, action) => {
            state.parentSubClassesAccount = action.payload.data
            state.error = null
        })
        builder.addCase(getParentSubClassAccount.rejected, (state, action) => {
            state.parentSubClassesAccount = []
            state.error = action.error.message || null
        })

        builder.addCase(getTypesClassesAccount.fulfilled, (state, action) => {
            state.typeClassesAccount = action.payload.data
            state.error = null
        })
        builder.addCase(getTypesClassesAccount.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getSubClassAccount.fulfilled, (state, action) => {
            state.subClassAccount = action.payload.data
            state.error = null
        })
        builder.addCase(getSubClassAccount.rejected, (state, action) => {
            state.error = action.error.message || null;
        })
        
        builder.addCase(getClassAccount.fulfilled, (state, action) => {
            state.classAccount = action.payload.data
            state.error = null
        })
        builder.addCase(getClassAccount.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(createClassAccount.fulfilled, (state, action) => {
            state.classAccount = action.payload.data
            state.ClassAccountCreateSuccess = true
            state.error = null
        })
        builder.addCase(createClassAccount.rejected, (state, action) => {
            state.error = action.error.message || null
            state.ClassAccountCreateSuccess = false
        })
        
        builder.addCase(updateClassAccount.fulfilled, (state, action) => {
            state.classAccount = action.payload.data
            state.ClassAccountUpdateSuccess = true
            state.error = null
        })
        builder.addCase(updateClassAccount.rejected, (state, action) => {
            state.error = action.error.message || null
            state.ClassAccountUpdateSuccess = false
        })
        
        builder.addCase(deleteClassAccount.fulfilled, (state, action) => {
            state.classAccount = action.payload.data
            state.ClassAccountDeleteSuccess = true
            state.error = null
        })
        builder.addCase(deleteClassAccount.rejected, (state, action) => {
            state.ClassAccountDeleteSuccess = false
            state.error = action.error.message || null
        })

        builder.addCase(searchClassAccount.fulfilled, (state, action) => {
            state.classesAccount = action.payload.data
            state.ClassAccountCreateSuccess = false
            state.ClassAccountUpdateSuccess = false
            state.ClassAccountDeleteSuccess = false
            state.error = null
        })
        builder.addCase(searchClassAccount.rejected, (state, action) => {
            state.error = action.error.message || null
        })


        //Warehouse Suppliers
        builder.addCase(getSuppliers.fulfilled, (state, action) => {
            state.suppliers = action.payload.data
            state.createSupplierSuccess = false
            state.updateSupplierSuccess = false
            state.deleteSupplierSuccess = false
            state.error = null
        })
        builder.addCase(getSuppliers.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getAllSuppliers.fulfilled, (state, action) => {
            state.allSuppliers = action.payload.data
            state.error = null
        })
        builder.addCase(getAllSuppliers.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(getSupplier.fulfilled, (state, action) => {
            state.supplier = action.payload.data
            state.error = null
        })
        builder.addCase(getSupplier.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(createSupplier.fulfilled, (state, action) => {
            state.supplier = action.payload.data
            state.createSupplierSuccess = true
            state.error = null
        })
        builder.addCase(createSupplier.rejected, (state, action) => {
            state.createSupplierSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(updateSupplier.fulfilled, (state, action) => {
            state.Supplier = action.payload.data
            state.updateSupplierSuccess = true
            state.error = null
        })
        builder.addCase(updateSupplier.rejected, (state, action) => {
            state.updateSupplierSuccess = false
            state.error = action.error.message || null
        })

        builder.addCase(deleteSupplier.fulfilled, (state, action) => {
            state.deleteSupplierSuccess = true
            state.error = null
        })
        builder.addCase(deleteSupplier.rejected, (state, action) => {
            state.deleteSupplierSuccess = false
            state.error = action.error.message || null
        })

        builder.addCase(searchSupplier.fulfilled, (state, action) => {
            state.suppliers = action.payload.data
            state.createSupplierSuccess = false
            state.updateSupplierSuccess = false
            state.deleteSupplierSuccess = false
            state.error = null
        })
        builder.addCase(searchSupplier.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        

        // Warehouse Products

        builder.addCase(getProducts.fulfilled, (state, action) => {
            state.products = action.payload.data
            state.createProductSuccess = false
            state.updateProductSuccess = false
            state.deleteProductSuccess = false
            state.error = null
        })
        builder.addCase(getProducts.rejected, (state, action) => {
            state.error = action.error.message || null
            
        })

        builder.addCase(getProductsAndSearch.fulfilled, (state, action) => {
            state.productsList = action.payload.data
            state.createProductSuccess = false
            state.updateProductSuccess = false
            state.deleteProductSuccess = false
            state.error = null
        })
        builder.addCase(getProductsAndSearch.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getProductsAndSearchStock.fulfilled, (state, action) => {
            state.productsListStock = action.payload.data
            state.createProductSuccess = false
            state.updateProductSuccess = false
            state.deleteProductSuccess = false
            state.error = null
        })
        builder.addCase(getProductsAndSearchStock.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getProduct.fulfilled, (state, action) => {
            state.product = action.payload.data
            state.error = null
        })
        builder.addCase(getProduct.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(searchProduct.fulfilled, (state, action) => {
            state.products = action.payload.data
            state.error = null
        })
        builder.addCase(searchProduct.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(createProduct.fulfilled, (state, action) => {
            state.product = action.payload.data
            state.createProductSuccess = true
            state.error = null

        })
        builder.addCase(createProduct.rejected, (state, action) => {
            state.createProductSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(updateProduct.fulfilled, (state, action) => {
            state.product = action.payload.data
            state.updateProductSuccess = true
            state.error = null
        })
        builder.addCase(updateProduct.rejected, (state, action) => {
            state.updateProductSuccess = false
            state.error = action.error.message || null
        })

        builder.addCase(deleteProduct.fulfilled, (state, action) => {
            state.deleteProductSuccess = true
            state.error = null
        })
        builder.addCase(deleteProduct.rejected, (state, action) => {
            state.deleteProductSuccess = false
            state.error = action.error.message || null
        })

        //Warehouse suppliers-order

        builder.addCase(getSuppliersOrder.fulfilled, (state, action) => {
            state.suppliersOrder = action.payload.data
            state.supplierOrder = {}
            state.createSupplierOrderSuccess = false
            state.updateSupplierOrderSuccess = false
            state.deleteSupplierOrderSuccess = false
            state.confirmSupplierOrderSuccess = false
            state.error = null
        })
        builder.addCase(getSuppliersOrder.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getSupplierOrder.fulfilled, (state, action) => {
            state.supplierOrder = action.payload.data
            state.error = null
        })
        builder.addCase(getSupplierOrder.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(addSupplierOrder.fulfilled, (state, action) => {
            state.supplierOrder = action.payload.data
            state.createSupplierOrderSuccess = true
            state.error = null
        })
        builder.addCase(addSupplierOrder.rejected, (state, action) => {
            state.createSupplierOrderSuccess = false
            state.error = action.error.message || null
        })

        builder.addCase(updateSupplierOrder.fulfilled, (state, action) => {
            state.supplierOrder = action.payload.data
            state.updateSupplierOrderSuccess = true
            state.error = null
        })
        builder.addCase(updateSupplierOrder.rejected, (state, action) => {
            state.updateSupplierOrderSuccess = false
            state.error = action.error.message || null
        })
        builder.addCase(confirmSupplierOrder.fulfilled, (state, action) => {
            state.supplierOrder = action.payload.data
            state.confirmSupplierOrderSuccess = true
            state.error = null
        })
        builder.addCase(confirmSupplierOrder.rejected, (state, action) => {
            state.confirmSupplierOrderSuccess = false
            state.error = action.error.message || null
        })

        builder.addCase(deleteSupplierOrderItem.fulfilled, (state, action) => {
            state.supplierOrder = action.payload.data
            state.deleteSupplierOrderSuccess = true
            state.error = null
        })
        builder.addCase(deleteSupplierOrderItem.rejected, (state, action) => {
            state.deleteSupplierOrderSuccess = false
            state.error = action.error.message || null
        })

        // Warehouse stock

        builder.addCase(getWarehouseStock.fulfilled, (state, action) => {
            state.stockReports = action.payload.data
            state.error = null
        })
        builder.addCase(getWarehouseStock.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        // Product Discount

        builder.addCase(getProductsDiscount.fulfilled, (state, action) => {
            state.productsDiscount = action.payload.data
            state.createProductDiscountSuccess = false
            state.updateProductDiscountSuccess = false
            state.deleteProductDiscountSuccess = false
            state.error = null
        })
        builder.addCase(getProductsDiscount.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getProductDiscount.fulfilled, (state, action) => {
            state.productDiscount = action.payload.data
            state.error = null
        })
        builder.addCase(getProductDiscount.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(createProductDiscount.fulfilled, (state, action) => {
            state.productDiscount = action.payload.data
            state.createProductDiscountSuccess = true
            state.error = null
        })
        builder.addCase(createProductDiscount.rejected, (state, action) => {
            state.error = action.error.message || null
            state.createProductDiscountSuccess = false
        })
        
        builder.addCase(updateProductDiscount.fulfilled, (state, action) => {
            state.productDiscount = action.payload.data
            state.updateProductDiscountSuccess = true
            state.error =  null
        })
        builder.addCase(updateProductDiscount.rejected, (state, action) => {
            state.updateProductDiscountSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(deleteProductDiscount.fulfilled, (state, action) => {
            state.supplierOrder = action.payload.data
            state.deleteProductDiscountSuccess = true
            state.error = null

        })
        builder.addCase(deleteProductDiscount.rejected, (state, action) => {
            state.error = action.error.message || null
            state.deleteProductDiscountSuccess = false
        })

        // Transfert orders

        builder.addCase(getTransfertOrders.fulfilled, (state, action) => {
            state.transfertOrders = action.payload.data
            state.transfertOrder = {}
            state.createTransfertOrderSuccess = false
            state.updateTransfertOrderSuccess = false
            state.confirmTransfertOrderSuccess = false
            state.error = null
        })
        builder.addCase(getTransfertOrders.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getTransfertOrder.fulfilled, (state, action) => {
            state.transfertOrder = action.payload.data
            state.error = null
        })
        builder.addCase(getTransfertOrder.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(createTransfertOrder.fulfilled, (state, action) => {
            state.transferOrder = action.payload.data
            state.createTransfertOrderSuccess = true
            state.error = null
        })
        builder.addCase(createTransfertOrder.rejected, (state, action) => {
            state.error = action.error.message || null
            state.createTransfertOrderSuccess = false
        })
        
        builder.addCase(updateTransfertOrder.fulfilled, (state, action) => {
            state.transferOrder = action.payload.data
            state.updateTransfertOrderSuccess = true
            state.error =  null
        })
        builder.addCase(updateTransfertOrder.rejected, (state, action) => {
            state.updateTransfertOrderSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(confirmTransfertOrder.fulfilled, (state, action) => {
            state.transferOrder = action.payload.data
            state.confirmTransfertOrderSuccess = true
            state.error = null

        })
        builder.addCase(confirmTransfertOrder.rejected, (state, action) => {
            state.error = action.error.message || null
            state.confirmTransfertOrderSuccess = false
        })

        // Pharmacy stock
        builder.addCase(getPharmacyStock.fulfilled, (state, action) => {
            state.phStockReports = action.payload.data
            state.error = null

        })
        builder.addCase(getPharmacyStock.rejected, (state, action) => {
            state.error = action.error.message || null
            state.phStockReports = []
        })

        builder.addCase(getPharmacyProductsAndSearch.fulfilled, (state, action) => {
            state.pharmacyProduct = action.payload.data
            state.error = null

        })
        builder.addCase(getPharmacyProductsAndSearch.rejected, (state, action) => {
            state.error = action.error.message || null
            state.pharmacyProduct = []
        })

        builder.addCase(getSales.fulfilled, (state, action) => {
            state.sales = action.payload.data
            state.createSaleSuccess = false
            state.error = null

        })
        builder.addCase(getSales.rejected, (state, action) => {
            state.error = action.error.message || null
            state.sales = []
        })

        builder.addCase(getSale.fulfilled, (state, action) => {
            state.sale = action.payload.data
            state.error = null

        })
        builder.addCase(getSale.rejected, (state, action) => {
            state.error = action.error.message || null
            state.sales = []
        })

        builder.addCase(createSale.fulfilled, (state, action) => {
            state.sale = action.payload.data
            state.createSaleSuccess = true
            state.error = null
        })
        builder.addCase(createSale.rejected, (state, action) => {
            state.error = action.error.message || null
            state.createSaleSuccess = false
            state.sale = {}
        })

        builder.addCase(getAssociatedService.fulfilled, (state, action) => {
            state.associatedService = action.payload.data
            state.error = null
        })
        builder.addCase(getAssociatedService.rejected, (state, action) => {
            state.error = action.error.message || null
            state.associatedService = []
        })
        

        builder.addCase(getInvoices.fulfilled, (state, action) => {
            state.saleInvoices = action.payload.data
            state.createSaleSuccess = false
            state.payment = {}
            state.error = null
        })
        builder.addCase(getInvoices.rejected, (state, action) => {
            state.error = action.error.message || null
            state.saleInvoices = []
        })

        builder.addCase(getPatientInvoice.fulfilled, (state, action) => {
            state.patientInvoices = action.payload.data
            state.error = null
        })
        builder.addCase(getPatientInvoice.rejected, (state, action) => {
            state.error = action.error.message || null
            state.patientInvoices = []
        })

        builder.addCase(getInvoiceStatus.fulfilled, (state, action) => {
            state.invoiceStatus = action.payload.data
            state.error = null
        })
        builder.addCase(getInvoiceStatus.rejected, (state, action) => {
            state.error = action.error.message
            state.invoiceStatus = []
        })

        builder.addCase(getPaymentMode.fulfilled, (state, action) => {
            state.paymentModes = action.payload.data
            state.error = null
        })
        builder.addCase(getPaymentMode.rejected, (state, action) => {
            state.error = action.error.message || null
            state.paymentModes = []
        })

        

        builder.addCase(getFinancialYears.fulfilled, (state, action) => {
            state.financialYears = action.payload.data
            state.financialYear = {}
            state.createFinancialYearSuccess = false
            state.closeFinancialYearSuccess = false
            state.openFinancialYearSuccess = false
            state.error = null
        })
        builder.addCase(getFinancialYears.rejected, (state, action) => {
            state.error = action.error.message || null
            state.financialYears = []
        })

        builder.addCase(getFinancialYear.fulfilled, (state, action) => {
            state.financialYear = action.payload.data
            state.error = null
        })
        builder.addCase(getFinancialYear.rejected, (state, action) => {
            state.error = action.error.message || null
            state.financialYear = {}
        })

        builder.addCase(createFinancialYear.fulfilled, (state, action) => {
            state.financialYear = action.payload.data
            state.createFinancialYearSuccess = true
            state.error = null
        })
        builder.addCase(createFinancialYear.rejected, (state, action) => {
            state.error = action.error.message || null
            state.createFinancialYearSuccess = false
        })

        builder.addCase(closeFinancialYear.fulfilled, (state, action) => {
            state.closeFinancialYearSuccess = true
            state.error = null
        })
        builder.addCase(closeFinancialYear.rejected, (state, action) => {
            state.error = action.error.message || null
            state.closeFinancialYearSuccess = false
        })

        builder.addCase(openFinancialYear.fulfilled, (state, action) => {
            state.openFinancialYearSuccess = true
            state.error = null
        })
        builder.addCase(openFinancialYear.rejected, (state, action) => {
            state.error = action.error.message || null
            state.openFinancialYearSuccess = false
        })

        builder.addCase(makePayment.fulfilled, (state, action) => {
            state.payment = action.payload.data
            state.makePaymentSuccess = true
            state.error = null
        })
        builder.addCase(makePayment.rejected, (state, action) => {
            state.payment = null
            state.error = action.error.message || null
            state.makePaymentSuccess = false
        })

        builder.addCase(getPatientAccounts.fulfilled, (state, action) => {
            state.patientAccounts = action.payload.data
            state.patientAccount = {}
            state.createPatientAccountSuccess = false
            state.makeDepositSuccess = false
            state.error = null
        })
        builder.addCase(getPatientAccounts.rejected, (state, action) => {
            state.error = action.error.message || null
            state.patientAccount = {}
        })

        builder.addCase(getPatientAccount.fulfilled, (state, action) => {
            state.patientAccount = action.payload.data
            state.error = null
        })
        builder.addCase(getPatientAccount.rejected, (state, action) => {
            state.error = action.error.message || null
            state.patientAccount = {}
        })

        builder.addCase(getPatientAccountHistory.fulfilled, (state, action) => {
            state.patientAccountHistory = action.payload.data
            state.error = null
        })
        builder.addCase(getPatientAccountHistory.rejected, (state, action) => {
            state.error = action.error.message || null
            state.patientAccountHistory = {}
        })


        builder.addCase(createPatientAccount.fulfilled, (state, action) => {
            state.patientAccount = action.payload.data
            state.createPatientAccountSuccess = true
            state.error = null
        })
        builder.addCase(createPatientAccount.rejected, (state, action) => {
            state.error = action.error.message || null
            state.createPatientAccountSuccess = false
        })

        builder.addCase(makeDeposit.fulfilled, (state, action) => {
            state.makeDepositSuccess = true
            state.error = null
        })
        builder.addCase(makeDeposit.rejected, (state, action) => {
            state.error = action.error.message || null
            state.makeDepositSuccess = false
        })

        // Payments
        builder.addCase(getPayments.fulfilled, (state, action) => {
            state.payments = action.payload.data
            state.payment = {}
            state.confirmPaymentSuccess = false
            state.error = null
        })
        builder.addCase(getPayments.rejected, (state, action) => {
            state.error = action.error.message || null
            state.payments = []
        })
        
        builder.addCase(getPayment.fulfilled, (state, action) => {
            state.payment = action.payload.data
            state.error = null
        })
        builder.addCase(getPayment.rejected, (state, action) => {
            state.error = action.error.message || null
            state.payment = {}
        })

        builder.addCase(confirmPayment.fulfilled, (state, action) => {
            state.confirmPaymentSuccess = true
            state.error = null
        })
        builder.addCase(confirmPayment.rejected, (state, action) => {
            state.error = action.error.message || null
            state.confirmPaymentSuccess = false
        })

        builder.addCase(getDeposits.fulfilled, (state, action) => {
            state.deposits = action.payload.data
            state.error = null
        })
        builder.addCase(getDeposits.rejected, (state, action) => {
            state.error = action.error.message || null
            state.deposits = []
        })

        // CashDesk

        builder.addCase(createCashDesk.fulfilled, (state, action) => {
            state.cashDesk = action.payload.data
            state.createCashDeskSuccess = true
            state.error = null
        })
        builder.addCase(createCashDesk.rejected, (state, action) => {
            state.createCashDeskSuccess = false
            state.error = action.error.message || null
        })

        builder.addCase(openCashDesk.fulfilled, (state, action) => {
            state.cashDesk = action.payload.data
            state.openCashDesksuccess = true
            state.error = null
        })
        builder.addCase(openCashDesk.rejected, (state, action) => {
            state.openCashDesksuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(closeCashDesk.fulfilled, (state, action) => {
            state.cashDesk = action.payload.data
            state.closeCashDesksuccess = true
            state.error = null
        })
        builder.addCase(closeCashDesk.rejected, (state, action) => {
            state.closeCashDesksuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(getCloseCashDesks.fulfilled, (state, action) => {
            state.closedCashDesk = action.payload.data
            state.error = null
        })
        builder.addCase(getCloseCashDesks.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(getCashDesks.fulfilled, (state, action) => {
            state.cashDesks = action.payload.data
            state.createCashDeskSuccess = false
            state.openCashDesksuccess = false
            state.updateCashDeskSuccess = false
            state.closeCashDesksuccess = false
            state.error = null
        })
        builder.addCase(getCashDesks.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(checkOpenCashdesk.fulfilled, (state, action) => {
            state.cashDeskOpen = action.payload.data
            state.error = null
        })
        builder.addCase(checkOpenCashdesk.rejected, (state, action) => {
            state.cashDeskOpen = null
            state.error = action.error.message || null
        })

        builder.addCase(getAmountsCollectedGroupBySources.fulfilled, (state, action) => {
            state.sourcesAndAmounts = action.payload.data
            state.error = null
        })
        builder.addCase(getAmountsCollectedGroupBySources.rejected, (state, action) => {
            state.sourcesAndAmounts = null
            state.error = action.error.message || null
        })
        
        builder.addCase(updateCashDesk.fulfilled, (state, action) => {
            state.cashDesk = action.payload.data
            state.updateCashDeskSuccess = true
            state.error = null
        })
        builder.addCase(updateCashDesk.rejected, (state, action) => {
            state.updateCashDeskSuccess = false
            state.error = action.error.message || null
        })

        // Cash journal
        builder.addCase(getCashJournal.fulfilled, (state, action) => {
            state.cashJournal = action.payload.data
            state.error = null
        })
        builder.addCase(getCashJournal.rejected, (state, action) => {
            state.cashJournal = []
            state.error = action.error.message || null
        })

        // Bank Account

        builder.addCase(getBankAccounts.fulfilled, (state, action) => {
            state.bankingAccounts =  action.payload.data
            state.createBankAccountSuccess = false
            state.updateBankAccountSuccess = false
            state.deleteBankAccountSuccess = false
            state.bankingAccount =  {}
            state.error = null
        })
        builder.addCase(getBankAccounts.rejected, (state, action) => {
            state.bankingAccounts =  []
            state.error = action.error.message || null
        })
        
        builder.addCase(getAllBankAccounts.fulfilled, (state, action) => {
            state.allBankingAccounts =  action.payload.data
            state.error = null
        })
        builder.addCase(getAllBankAccounts.rejected, (state, action) => {
            state.error = action.error.message || null
            state.allBankingAccounts =  {}
        })
        
        builder.addCase(createBankAccount.fulfilled, (state, action) => {
            state.bankingAccount =  action.payload.data
            state.createBankAccountSuccess = true
            state.error = null
        })
        builder.addCase(createBankAccount.rejected, (state, action) => {
            state.createBankAccountSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(updateBankAccount.fulfilled, (state, action) => {
            state.bankingAccount =  action.payload.data
            state.updateBankAccountSuccess = true
            state.error = null
        })
        builder.addCase(updateBankAccount.rejected, (state, action) => {
            state.updateBankAccountSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(deleteBankAccount.fulfilled, (state, action) => {
            state.bankingAccount =  action.payload.data
            state.deleteBankAccountSuccess = true
            state.error = null
        })
        builder.addCase(deleteBankAccount.rejected, (state, action) => {
            state.deleteBankAccountSuccess = false
            state.error = action.error.message || null
        })

        // Budgeting

        builder.addCase(getBudgeting.fulfilled, (state, action) => {
            state.budgetings =  action.payload.data
            state.error = null
        })
        builder.addCase(getBudgeting.rejected, (state, action) => {
            state.error = action.error.message || null
            state.budgetings =  {}
        })
        
        builder.addCase(createBudgeting.fulfilled, (state, action) => {
            state.budgeting =  action.payload.data
            state.createBudgetingSuccess = true
            state.error = null
        })
        builder.addCase(createBudgeting.rejected, (state, action) => {
            state.createBudgetingSuccess = false
            state.error = action.error.message || null
        })

        // Requisition

        builder.addCase(getRequisitions.fulfilled, (state, action) => {
            state.requisitions = action.payload.data
            state.createRequisitionSuccess = false
            state.approveRequisitionSuccess = false
            state.rejectRequisitionSuccess = false
            state.payRequisitionSuccess = false
            state.cancelRequisitionSuccess = false
            state.updateRequisitionSuccess = false
            state.error = null
        })
        builder.addCase(getRequisitions.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(getRequisition.fulfilled, (state, action) => {
            state.requisition = action.payload.data
            state.error = null
        })
        builder.addCase(getRequisition.rejected, (state, action) => {
            state.requisition = {}
            state.error = action.error.message || null
        })
        
        builder.addCase(getRequisitionStatus.fulfilled, (state, action) => {
            state.requisitionStatus = action.payload.data
            state.error = null
        })
        builder.addCase(getRequisitionStatus.rejected, (state, action) => {
            state.error = action.error.message || null
            state.requisitionStatus = []
        })
        
        builder.addCase(createRequisition.fulfilled, (state, action) => {
            state.requisition = action.payload.data
            state.createRequisitionSuccess = true
            state.error = null
        })
        builder.addCase(createRequisition.rejected, (state, action) => {
            state.createRequisitionSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(approveRequisition.fulfilled, (state, action) => {
            state.requisition = action.payload.data
            state.approveRequisitionSuccess = true
            state.error = null
        })
        builder.addCase(approveRequisition.rejected, (state, action) => {
            state.approveRequisitionSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(rejectRequisition.fulfilled, (state, action) => {
            state.requisition = action.payload.data
            state.rejectRequisitionSuccess = true
            state.error = null
        })
        builder.addCase(rejectRequisition.rejected, (state, action) => {
            state.rejectRequisitionSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(payRequisition.fulfilled, (state, action) => {
            state.requisition = action.payload.data
            state.payRequisitionSuccess = true
            state.error = null
        })
        builder.addCase(payRequisition.rejected, (state, action) => {
            state.payRequisitionSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(cancelRequisition.fulfilled, (state, action) => {
            state.requisition = action.payload.data
            state.cancelRequisitionSuccess = true
            state.error = null
        })
        builder.addCase(cancelRequisition.rejected, (state, action) => {
            state.cancelRequisitionSuccess = false
            state.error = action.error.message || null
        })
        
        builder.addCase(updateRequisition.fulfilled, (state, action) => {
            state.requisition = action.payload.data
            state.updateRequisitionSuccess = true
            state.error = null
        })
        builder.addCase(updateRequisition.rejected, (state, action) => {
            state.updateRequisitionSuccess = false
            state.error = action.error.message || null
        })

        // CashDrop

        builder.addCase(getCashDrops.fulfilled, (state, action) => {
            state.cashdrops = action.payload.data
            state.createCashDropSuccess = false
            state.error = null
        })
        builder.addCase(getCashDrops.rejected, (state, action) => {
            state.error = action.error.message || null
        })
        
        builder.addCase(createCashDrop.fulfilled, (state, action) => {
            state.cashDrop = action.payload.data
            state.createCashDropSuccess = true
            state.error = null
        })
        builder.addCase(createCashDrop.rejected, (state, action) => {
            state.createCashDropSuccess = false
            state.error = action.error.message || null
        })

        // Other Revenues
        
        builder.addCase(getOtherRevenues.fulfilled, (state, action) => {
            state.otherRevenues = action.payload.data
            state.createOtherRevenueSuccess = false
            state.error = null
        })
        builder.addCase(getOtherRevenues.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(createOtherRevenue.fulfilled, (state, action) => {
            state.otherRevenue = action.payload.data
            state.createOtherRevenueSuccess = true
            state.error = null
        })
        builder.addCase(createOtherRevenue.rejected, (state, action) => {
            state.createOtherRevenueSuccess = false
            state.error = action.error.message || null
        })

        // Print Setting
        builder.addCase(getPrintSetting.fulfilled, (state, action) => {
            state.printSettings = action.payload.data
            state.savePrintSettingSuccess = false
            state.error = null
        })
        builder.addCase(getPrintSetting.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(savePrintSetting.fulfilled, (state, action) => {
            state.printSettings = action.payload.data
            state.savePrintSettingSuccess = true
            state.error = null
        })
        builder.addCase(savePrintSetting.rejected, (state, action) => {
            state.savePrintSettingSuccess = false
            state.error = action.error.message || null
        })


        // Account summary
        builder.addCase(getAccountSummary.fulfilled, (state, action) => {
            state.accountSummary = action.payload.data
            state.error = null
        })
        builder.addCase(getAccountSummary.rejected, (state, action) => {
            state.error = action.error.message || null
        })

        builder.addCase(getAccountSummaryDetails.fulfilled, (state, action) => {
            state.summaryDetail = action.payload.data
            state.error = null
        })
        builder.addCase(getAccountSummaryDetails.rejected, (state, action) => {
            state.error = action.error.message || null
        })
    }
})

export default SettingsSlice.reducer;