import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

import withRouter from '../../Common/withRouter'

function PurchaseRow(props) {

    const [quantity, setQuantity] = useState(props.row.quantity)
    const [price, setPrice] = useState(props.row.price)
    const [amount, setAmmount] = useState(props.row.price)

    useEffect(() =>{
        if (quantity > 0) {
            setAmmount(quantity*price)
        }
    }, [quantity, price])

    useEffect(() =>{
        if (amount > 0) {
            if (props.isValidate) {
                let object = {
                    productId: props.row.productId,
                    name: props.row.name,
                    price: price,
                    quantity: props.row.quantity,
                    deliveryQuantity: quantity,
                    amount: amount
                }
                props.updateRow(object)
            } else {
                let object = {
                    productId: props.row.productId,
                    name: props.row.name,
                    price: price,
                    quantity: quantity,
                    amount: amount
                }
                props.updateRow(object)
            }
        }
    },[amount])
    
    const handleBlur = (e, setValue) => {
        if (e.target.value === '' || e.target.value === '0') {
            setValue(1);
        }
    };

  return (
    < >
        <td className="text-center">{props.index+1}</td>
        <td>{props.row.name}</td>
        <td className="text-center">
            <input type="number" className="form-control m-0" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))} onBlur={(e) => handleBlur(e, setQuantity)} aria-label="Text input with checkbox" disabled = {props.isWatch ? true : false} />
        </td>
        <td>
            <input type="number" className="form-control m-0" value={price} onChange={(e) => setPrice(Number(e.target.value))} onBlur={(e) => handleBlur(e, setPrice)} aria-label="Text input with checkbox" disabled = {props.isValidate ? true : false} />
        </td>
        <td>{amount} {props.t("current_device")}</td>
        {!props.isWatch ?
            <td className="text-center" onClick={() => props.deleteRow(props.row) } >
                <i className="fa-solid fa-trash-can delete-action-btn"></i>
            </td>
            :
            <></>
        }
    </>
  )
}

export default withRouter(withTranslation()(PurchaseRow))
