import React, { useCallback, useEffect, useState } from 'react'
import withRouter from '../../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { createSelector } from "reselect"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
    getPharmacyProductsAndSearch as onGetPharmacyProductsAndSearch,
    getSale as onGetSale,
    getPatientAccount as onGetPatientAccount,
    searchPatient as onSearchPatient,
    createSale as onCreateSale,
    getPaymentMode as onGetPaymentMode
} from '../../../../slice/thunks'
import { Col, Label } from 'reactstrap'
import SaleRow from '../../../../components/App/Sales/SaleRow'
import DeleteModal from '../../../../components/Global/DeleteModal'
import PrintInvoiceComponent from '../../../../components/Global/PrintOutputs/Invoices/PrintInvoiceComponent'



const SalesCahier = (props) => {
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const selectLayoutStatePatient = (state) => state.Outputs;

    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            pharmacyProduct: setting.pharmacyProduct,
            patientAccount: setting.patientAccount,
            paymentModes: setting.paymentModes,
            createSaleSuccess: setting.createSaleSuccess,
            sale: setting.sale,
            error: setting.error
        })
    )
    const OutputsProperties = createSelector(
        selectLayoutStatePatient,
        (outputs) =>({
            patients: outputs.patients,
            patient: outputs.patient,
        })
    )

    const { pharmacyProduct, patientAccount, createSaleSuccess, sale, paymentModes, error } = useSelector(SettingsProperties)
    const { patients } = useSelector(OutputsProperties)
    const [ patientOption, setPatientOption ]= useState([])
    const [ paymentOption, setPaymentOption ] = useState(null)
    const [ productsOption, setProductsOption] = useState([])
    const [ selectedPatient, setSelectedPatient ] = useState(null)
    const [ selectedTarget, setSelectedTarget] = useState(null)
    const [ selectedPaymentM, setSelectedPaymentM ] = useState(null)
    const [ selectedProduct, setSelectedProduit] = useState(null)
    const [ currentPatientAccount, setCurrentPatientAccount] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [ items, setItems ] = useState([])
    const [ onHold, setOnHold] = useState(false)
    const [ onKeepBalance, setOnKeepBalance] = useState(false)
    const [ totalAmount, setTotalAmount ] = useState(0)
    const [amountRecieved, setAmountRecieved ] = useState(0)
    const [amountReturn, setAmountReturn ] = useState(0)
    const [ onHold1, setOnHold1 ] = useState(false)
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [modalCashier, setModalCashier] = useState(false)

    useEffect(() => {
        if (patients && patients.items && patients.items.length > 0) {
            const patient_list = patients.items.filter(target => target.patientRecord).map( target => {
                return {
                    label: target.firstName+' '+target.lastName,
                    value: target.patientRecord ? target.patientRecord.id : null,
                    type: target.patientType.typeRef,
                    id: target.id
                }
            })
            setPatientOption(patient_list)
        }
    },[patients])

    useEffect(() =>{
        if (!props.sale) {
            setItems([])
        }
    },[selectedPatient])

    useEffect(() => {
        if (props.sale && props.sale.id) {
            loadSale(props.sale.id)
            setSelectedPatient({
                label: props.sale.patient.firstName+' '+props.sale.patient.lastName,
                value: props.sale.patientRecord ? props.sale.patientRecord.id : null,
                type: props.sale.patient.patientType.typeRef,
                id: props.sale.patient ? props.sale.patient.id : null
            })

            let array_items = []

            props.sale.items.forEach(element => {
                let row = {
                    label: element.product.name,
                    value: element.product.id,
                    qtyStock: element.product.quantity,
                    type: element.product.productType,
                    unitPrice: element.unitPrice,
                    discount: element.discount,
                    quantity: element.quantity
                }
                array_items.push(row)
            });

            setItems(array_items)
        }

    }, [props.sale])

    useEffect(() => {
        if (paymentModes && paymentModes.length > 0) {
            const payment_lists = paymentModes.map( mode => {
              return { label: mode.title, value: mode.value }
            })
            setPaymentOption(payment_lists)
        }
    },[paymentModes])

    useEffect(() => {
        if (selectedPaymentM && selectedPaymentM.value == 'PATIENT_CREDIT') {
            loadPatientAccount()
        } else {
            setCurrentPatientAccount(null)
        }
    },[selectedPaymentM, selectedPatient])

    useEffect(() => {
        if (patientAccount && patientAccount.balance) {
            setCurrentPatientAccount(patientAccount);
        }
    },[patientAccount])

    useEffect(() => {
        if ((selectedPatient && selectedPatient.type)) {
            loadProducts(selectedPatient.type)
        }
    },[selectedTarget, selectedPatient])

    useEffect(() => {
        if (createSaleSuccess && !error) {
            if (onHold1) {
                history("/cashier/all-sales")
                props.setIsCreate(false)
                setOnHold1(false)
            } else {
                toggleCashier()                
            }
            setOnHold(false)
            setOnKeepBalance(false)
            setCurrentPatientAccount(null)
        }
    },[createSaleSuccess])

    useEffect(() => {
        if (pharmacyProduct && pharmacyProduct.length > 0) {
            const product_list = pharmacyProduct.map( prod => {
                return {
                    label: prod.name,
                    value: prod.id,
                    qtyStock: prod.quantity,
                    unitPrice: prod.unitPrice,
                    discount: prod.discount,
                    rowTotal: (props.unitPrice * 1) - (((props.unitPrice * 1)*props.discount)/100),
                    type: prod.productType,
                    quantity: 1
                }
            })

            setProductsOption(product_list)
        }

    },[pharmacyProduct])

    useEffect(() => {
        loadPatients()
        loadPaymentMode()
    },[dispatch])

    useEffect(() => {
        if (selectedProduct !== null) {
            let current_products = [...items];
            if (!current_products.some((current) => current.value === selectedProduct.value)) {
              const new_products = [...current_products, selectedProduct];
              setItems(new_products);
            }
        }
    }, [selectedProduct]);

    useEffect(() => {
        let total_amount = 0
        if (items && items.length > 0) {
            items.forEach(row => {
                total_amount = total_amount+(row.rowTotal ? row.rowTotal : 0)
            });
            setTotalAmount(total_amount)
        } else {
            setTotalAmount(0)
        }

        setAmountRecieved()
    },[items])

    useEffect(() => {
        if (amountRecieved > 0) {
            setAmountReturn(amountRecieved-totalAmount)
        } else {
            setAmountReturn(totalAmount)
        }
    },[amountRecieved])

    const loadPatients = useCallback((term) => {
        if (term) {
            dispatch(onSearchPatient({term: term, page: 1, size: 10}))
        } else {
          dispatch(onSearchPatient({term: '', page: 1, size: 10}))
        }
    },[])

    const loadSale = useCallback((id) => {
        dispatch(onGetSale(id))
    })

    const loadPatientAccount = useCallback(() => {
        dispatch(onGetPatientAccount(selectedPatient ? selectedPatient.id : 0))
    })

    const loadProducts = useCallback((currentType, target, term) => {
        if (term) {
            dispatch(onGetPharmacyProductsAndSearch({ term: term, type: currentType, target: target }))
        } else {
            dispatch(onGetPharmacyProductsAndSearch({ type: currentType, target: target }))
        }
    },[])


    const loadPaymentMode = useCallback(() => {
        dispatch(onGetPaymentMode())
    },[])

    const toggleCashier = useCallback(() => {
        if (modalCashier) {
          setModalCashier(false);
          handlePrintInvoice();
        } else {
          setModalCashier(true);
        }
    }, [modalCashier])


    const handleSelectPatient =(selectedP) => {
        if (selectedP) {
            setSelectedPatient(selectedP)
        } else {
            setSelectedPatient(null)
            loadPatients()
        }
        setMenuIsOpen(false)
    }

    const handleSelectProduct =(selectedP) => {
        setSelectedProduit(selectedP)
    }

    const handleSelectMethod =(selectedM)  =>{
        setSelectedPaymentM(selectedM)
    }

    const handleUpdateRow = (row) => {
        let current_products = [...items];
        const index = current_products.findIndex(objet => objet.value === row.value);
        if (index !== -1) {
          current_products[index] = { ...current_products[index], ...row };
          setItems(current_products);
        }
    }

    const onClickDelete = (row) => {
        setSelectedProduit(row)
        setDeleteModal(true);
    }

    const handleDelete = () => {
        if (selectedProduct) {
          if (selectedProduct.id) {
          } else {
            let current_products = [...items];
            current_products = current_products.filter((prod) => prod.value !== selectedProduct.value )
            setItems(current_products);
          }
          setDeleteModal(false)
        }
    }

    const handleSearchProduct = (value) => {
        if ((selectedPatient && selectedPatient.type)) {
            loadProducts(selectedPatient.type)
        }
    }

    const loadDefaultProducts = () => {
        if ((selectedPatient && selectedPatient.type) && (selectedTarget && selectedTarget.value) ) {
            loadProducts(selectedPatient.type, selectedTarget.value)
        }
    }

    const saveBills = (hold) => {
        let itemsObj = items.map(item => {
            return {
              productId: item.value,
              quantity: item.quantity,
              unitPrice: item.unitPrice,
              finalPrice: item.unitPrice,
              discount: item.discount
            };
        });

        if (hold) {
            setOnHold1(hold)
        }

        let bill = {
            patientRecordId: selectedPatient.value,
            paymentMode: onHold ? null : selectedPaymentM ? selectedPaymentM.value : null,
            amountReceived: Number(amountRecieved),
            salesOnHold: onHold ? true : hold ? true : false,
            keepBalance: onKeepBalance ? true : false,
            items: itemsObj
        }

        dispatch(onCreateSale(bill))
    }

    const handlePrintInvoice = () => {
        setSelectedPatient(null)
        setSelectedTarget(null)
        setSelectedProduit(null)
        setSelectedPaymentM(null)
        setTotalAmount(0)
        setAmountRecieved(0)
        setAmountReturn(0)
        props.setIsCreate(false)
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided) => ({
            ...provided,
            width: '100%',
        }),
    };

  return (
    <>
        <div className="home-tab-content">
            <div className="heading-parent-sec" onClick={() => props.setIsCreate(false) } style={{ cursor: "pointer" }}>
                <div className="heading-child-one-sec d-flex pt-4">
                    <i className="fa-solid fa-chevron-left pt-1"></i>
                    <h6>{props.sale ? props.t("sale_on_hold") : props.t("new_sales")}</h6>
                </div>
            </div>
            <div className="row">
                <Col className="col-lg-8 pe-lg-0">
                    <div className="tab-content-left-part">
                        <div className="head-area" style={{ zIndex: "1000" }}>
                            <Col xl={4} sm={4} className='px-2'>
                                <div className='mb-2'>
                                    <Label htmlFor="patient-field" className="form-label">
                                        {props.t("patient")}<span className='text-danger'>*</span>
                                    </Label><br/>
                                    <Select
                                        key={patientOption.length}
                                        name='patient'
                                        id='patient-field'
                                        value={selectedPatient}
                                        isMulti={false}
                                        isClearable={true}
                                        onChange={(e) => {
                                            handleSelectPatient(e);
                                        }}
                                        onInputChange={(inputValue, { action }) => {
                                            if (action === 'input-change') {
                                                if (inputValue.length > 0) {
                                                    loadPatients(inputValue)
                                                } else {
                                                }
                                            }
                                        }}
                                        options={patientOption}
                                        closeMenuOnSelect={true}
                                        menuIsOpen={menuIsOpen}
                                        onMenuOpen={() => setMenuIsOpen(true)} 
                                        onMenuClose={() => setMenuIsOpen(false)}
                                        onBlur={() => setMenuIsOpen(false)}
                                        components={animatedComponents}
                                        isDisabled= {props.sale && props.sale.id ? true : false }
                                    />
                                </div>
                            </Col>
                            <Col xl={4} sm={4} className='px-2'>
                                <div className='mb-2'>
                                    <Label htmlFor="product-field" className="form-label">
                                        {props.t("product")}
                                    </Label><br/>
                                    <Select
                                        name='product'
                                        id='product-field'
                                        value=""
                                        isMulti={false}
                                        isClearable={false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            handleSelectProduct(e);
                                        }}
                                        onInputChange={(inputValue, { action }) => {
                                            if (action === 'input-change') {
                                                if (inputValue.length > 0) {
                                                    handleSearchProduct(inputValue)
                                                } else {
                                                    loadDefaultProducts()
                                                }
                                            }
                                        }}
                                        onMenuOpen={() => {
                                            loadDefaultProducts()
                                        }}
                                        options={productsOption}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        isDisabled= { selectedPatient && selectedPatient.value ? false : true}
                                    />
                                </div>
                            </Col>
                        </div>

                        <div className="table-area show" id="home-table">
                            <table className="table">
                                <thead>
                                    <tr scope="row">
                                        <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                        <th style={{ width: "20%" }}>{props.t("item_name")}</th>
                                        <th style={{ width: "10%" }}>{props.t("price_unit")}</th>
                                        <th style={{ width: "10%" }}>{props.t("discount")}</th>

                                        <th style={{ width: "10%" }}>{props.t("qty")}</th>
                                        <th style={{ width: "10%" }} colSpan="2">{props.t("total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {items && items.map((row, index) => (
                                    <tr key={row.value}>
                                      <SaleRow row={row} index={index} updateRow= {handleUpdateRow} deleteRow={onClickDelete}/>
                                    </tr>
                                  ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>

                {/* invoiceSide */}
                <div className="col-lg-4 ps-lg-0">
                    <div className="tab-content-right-part">

                        <div className="customer-detail">
                            <h6 className="title">{props.t("customer_detail")}</h6>
                            <h6>{ selectedPatient && selectedPatient.label }</h6>
                        </div>

                        <div className="bill-detail">
                            <h6 className="title">{props.t("bill_details")}</h6>
                            <div className="detail-row">
                                <p className="tag">{props.t("sub_total")}</p>
                                <p className="price">{totalAmount}</p>
                            </div>
                            <div className="total-row">
                                <p className="tag">{props.t("total_amount")}</p>
                                <p className="price">{totalAmount}</p>
                            </div>
                        </div>

                        <div className="payment-detail">
                            <div className="payment-mode">
                                <Label htmlFor="payment-field" className="form-label">{props.t("payment_mode")}</Label><br/>
                                <Select
                                    name='payment'
                                    id='payment-field'
                                    styles={customStyles}
                                    value={selectedPaymentM}
                                    isMulti={false}
                                    isClearable={false}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        handleSelectMethod(e);
                                    }}
                                    options={paymentOption}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                />
                            </div>
                            <div className="payment-mode">
                                <label>{props.t("amount_recieved")}</label>
                                <input
                                    className="form-control"
                                    type='number'
                                    value={amountRecieved}
                                    onChange={(e) => {setAmountRecieved(e.target.value) }}
                                    disabled= {selectedPaymentM && selectedPaymentM.value == 'PATIENT_CREDIT' ? true : false}
                                />
                            </div>

                            {currentPatientAccount && currentPatientAccount.balance ?
                                <div className="total-row">
                                    <p className="tag">{props.t("patient_credit_balance")}:</p>
                                    <p className="price">{currentPatientAccount.balance}</p>
                                </div>
                                :
                                <div className="total-row">
                                    <p className="tag">{props.t("amount_return")}:</p>
                                    <p className="price">{amountReturn && amountReturn > 0 ? Math.round(amountReturn) : 0}</p>
                                </div>
                            }
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onClick={() => {
                                        setOnKeepBalance(!onKeepBalance);
                                    }}
                                />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {props.t("keep_balance")}
                                </label>
                            </div>

                            <div className="btn-first-area d-flex justify-content-between pb-2" style={{ gap: "10px" }}>
                                <button
                                    className="bill-on-hold-btn show"
                                    style = { onHold ? { background: "#2563eb", color: "#fff" } : {}}
                                    id="bill-on-btn"
                                    onClick={() => {
                                        let hold = true
                                        saveBills(hold);
                                        if (onKeepBalance) {
                                            setOnKeepBalance(false)
                                        }
                                    }}
                                    disabled= { items && items.length > 0 ? false : true }
                                >
                                    {props.t("bill_on_hold")}
                                </button>
                            </div>
                        </div>

                        <div className="btn-area">
                            <button
                                className="print-btn"
                                id="save-print-btn"
                                onClick={(e) => {
                                    saveBills();
                                }}
                                disabled= {
                                    ((selectedPaymentM != null) || onHold  || onKeepBalance || (totalAmount >= 0)) && (items && items.length > 0) ? false : true}
                            >
                                    {props.t("save_print")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() =>setDeleteModal(false)} />

        <PrintInvoiceComponent
             isOpen={modalCashier || false }
            toggleCashier={toggleCashier} 
            module="cashier"
            section="sale"
            items={items} 
            sale={sale} 
            totalAmount={totalAmount} 
            amountRecieved={amountRecieved}
        />

    </>
  )
}

export default withRouter(withTranslation()(SalesCahier))
