import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom"
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../components/Common/withRouter'
import { 
    addNewPatient as onAddNewPatient,
    updatePatient as onUpdatePatient,
    getPatientTypes as onGetPatientTypes
} from "../../../../slice/thunks"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import * as Yup from "yup"
import { useFormik } from 'formik'
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap'
import GlobalLoader from '../../../../components/Common/GlobalLoader'

const PatientRegistration = (props) => {
    const dispatch = useDispatch()
    let { id, action } = useParams();
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Outputs
    const OutputsProperties = createSelector(
        selectLayoutState,
        (output) =>({
            patientTypes: output.patientTypes,
            patient_success: output.patient_success,
            patient: output.patient,
            error: output.error
        })
    )
    const { patientTypes, patient, patient_success, error } = useSelector(OutputsProperties)
    const [typeOptions, setTypeOptions] = useState([])
    const [selectedType, setSelectedType] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedGender, setSelectedGender] = useState(null)
    const [saveNext, setSaveNext] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [currentPatient, setCurrentPatient] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [errorType, setErrorType] = useState(false)
    const [errorGender, setErrorGender] = useState(false)

    const regionListe = [
        {label: 'Adamawa', value: 'Adamawa' },
        {label: 'Centre', value: 'Centre' },
        {label: 'East', value: 'East' },
        {label: 'Far North', value: 'Far North' },
        {label: 'Littoral', value: 'Littoral' },
        {label: 'North', value: 'North' },
        {label: 'North West', value: 'North West' },
        {label: 'West', value: 'West' },
        {label: 'South', value: 'South' },
        {label: 'South West', value: 'South West'}
    ]
 
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            patientRef: isEdit ? currentPatient && currentPatient.patientRef : "",
            firstName: isEdit ? currentPatient && currentPatient.firstName : "",
            lastName: isEdit ? currentPatient && currentPatient.lastName : "",
            dob: isEdit ? currentPatient && currentPatient.dob : "",
            gender: isEdit ? currentPatient && currentPatient.gender : "",
            phone: isEdit ? currentPatient && currentPatient.phone : "",
            email: isEdit ? currentPatient && currentPatient.email : "",
            patientType: isEdit ? currentPatient && currentPatient.patientType && currentPatient.typeName : "",
            profession: isEdit ? currentPatient && currentPatient.profession : "",
            region: isEdit ? currentPatient && currentPatient.address && currentPatient.address.region : "",
            city: isEdit ? currentPatient && currentPatient.address && currentPatient.address.city : "",
            address: isEdit ? currentPatient && currentPatient.address && currentPatient.address.address: "",
            relationship: isEdit ? currentPatient && currentPatient.contactPerson && currentPatient.contactPerson.relationship: "",
            contact_fullName: isEdit ? currentPatient && currentPatient.contactPerson && currentPatient.contactPerson.fullName : "",
            contact_phoneNumber: isEdit ? currentPatient && currentPatient.contactPerson && currentPatient.contactPerson.phoneNumber : "",
            contact_email: isEdit ? currentPatient && currentPatient.contactPerson && currentPatient.contactPerson.email : "",
            contact_profession: isEdit ? currentPatient && currentPatient.contactPerson && currentPatient.contactPerson.profession : ""
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required(props.t("provide_patient_name")),
            phone: Yup.string().required(props.t("provide_patient_phone")),
            dob: Yup.string().required(props.t("provide_patient_birth")),
            address: Yup.string().required(props.t("provide_patient_address")),
            relationship: Yup.string().required(props.t("provide_patient_relationship")),
            contact_fullName: Yup.string().required(props.t("provide_contact_name")),
            contact_phoneNumber: Yup.string().required(props.t("provide_contact_phone")),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updatePatientForm = {
                    patientRef: values["patientRef"],
                    firstName: values["firstName"],
                    lastName: values["lastName"],
                    dob: values["dob"],
                    gender: selectedGender,
                    phone: values["phone"],
                    email: values["email"],
                    patientTypeId: selectedType && selectedType.value,
                    profession: values.profession,
                    region: selectedRegion && selectedRegion.value || null,
                    city: values["city"],
                    address: values["address"],
                    contactPerson: {
                        relationship: values["relationship"],
                        fullName: values["contact_fullName"],
                        phoneNumber: values["contact_phoneNumber"],
                        email: values["contact_email"],
                        profession: values["contact_profession"]
                    }
                }
                dispatch(onUpdatePatient({id:currentPatient.id, data:updatePatientForm}))
            } else {
                const newPatientForm = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    dob: values.dob,
                    gender: selectedGender,
                    phone: values.phone,
                    email: values.email,
                    patientTypeId: selectedType && selectedType.value || null,
                    profession: values.profession,
                    region: selectedRegion && selectedRegion.value || null,
                    city: values.city,
                    address: values.address,
                    contactPerson: {
                        relationship: values.relationship,
                        fullName: values.contact_fullName,
                        phoneNumber: values.contact_phoneNumber,
                        email: values.contact_email,
                        profession: values.contact_profession
                    }
                }
                setIsEdit(false)
                dispatch(onAddNewPatient(newPatientForm))
            }
        }
    })

    const loadTypes = useCallback(() => {
        dispatch(onGetPatientTypes())
    }, [dispatch])

    useEffect(() =>{
        if (!id) {
            setIsLoading(false)
        }
    },[id])

    useEffect(() => {
        if (currentPatient && currentPatient.patientRef) {
            setIsLoading(false)
        }
    },[currentPatient])

    useEffect(() => {
        if (selectedType && selectedType.value) {
            setErrorType(false)
        }
    },[selectedType])

    useEffect(() => {
        if (selectedGender) {
            setErrorGender(false)
        }
    },[selectedGender])

    useEffect(() => {
        if (id) {
            setIsEdit(true);
            setCurrentPatient(props.patient);

            if (props.patient) {
                setSelectedGender(props.patient.gender);
                if (props.patient.address && props.patient.address.region) {
                    setSelectedRegion({
                        label: props.patient.address.region,
                        value: props.patient.address.region
                    });
                }

                if (props.patient.patientType && props.patient.patientType.typeName) {
                    setSelectedType({
                        label: props.patient.patientType.typeName,
                        value: props.patient.patientType.id
                    });
                }
            }
        }

    }, [id, props.patient]);

    useEffect(() => {
        if (props.isActive) {
            loadTypes()
        }
    },[dispatch, props.isActive, loadTypes])


    useEffect(() =>{
        if (patient_success) {
            if (saveNext && !isEdit) {
                props.handleTabChange("patient_vital-content")
            }
            else if (saveNext && isEdit){
                history(`/patients/details/${id}`)
            }
            validation.resetForm()
            setSelectedType(null)
            setSelectedRegion(null)
            setSelectedGender(null)
        }
    },[patient_success, patient, saveNext])

    useEffect(() =>{
        if (patientTypes && patientTypes.length > 0) {
          const types_list = patientTypes.map( type => {
            return { label: type.typeName, value: type.id }
          })
          setTypeOptions(types_list)
        }
    },[patientTypes])

    function handleSelectType(selectedType) {
        setSelectedType(selectedType)
    }

    function handleSelectRegion(selectedRegion) {
        setSelectedRegion(selectedRegion)
    }

    function handleSelectGender(selectedGender) {
        setSelectedGender(selectedGender)
    }

    const checkInput = () => {
        if(!selectedType) {
            setErrorType(true)
        }
        if (!selectedGender) {
            setErrorGender(true)
        }
    }

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="dashboard-add-new-patient-sec p-0 m-0">
                    <div className="container-fluid">
                        <div className="add-new-patient-main-sec">
                            <div className="add-new-patient-heading-sec">
                                <div className="add-new-patient-heading">
                                    {isEdit ?
                                        <p>{props.t("edit_patient")}</p>
                                        :
                                        <p>{props.t("add_new_patient")}</p>
                                    }
                                </div>
                                {isEdit ?
                                <div className="patient-id-sec">
                                    <p>{props.t("patient_id")} - <span className="text-primary">{currentPatient.patientRef}</span></p>
                                </div>
                                : ""
                                }
                            </div>

                            <Form 
                                className="add-new-patient-form-sec"
                                autoComplete="off"
                                onSubmit={(e) => { 
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row className='mb-3'>
                                    {isEdit ?
                                        <Col xl={4} sm={4}>
                                            <div className='mb-2'>
                                                <Label htmlFor="patientRef-field" className="form-label">{props.t("patientId")}<span className='text-danger'>*</span></Label><br/>
                                                <Input 
                                                    name="patientRef" id='patientRef-field' className="form-control" 
                                                    placeholder={props.t("patientId_placeholder")} type="text"
                                                    // onKeyPress={(e) => validateInput(e)}
                                                    validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur} value={validation.values.patientRef || ""}
                                                    disabled = {isEdit ? true: false}
                                                    style={{ textTransform: 'uppercase' }}
                                                    pattern="[A-Za-z0-9]*"
                                                    autoComplete="off"
                                                    invalid={
                                                        validation.touched.patientRef && validation.errors.patientRef ? true : false
                                                    } 
                                                />
                                                {validation.touched.patientRef && validation.errors.patientRef ? (
                                                    <FormFeedback type="invalid">{validation.errors.patientRef}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        : ""
                                    }
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="firstName-field" className="form-label">{props.t("firstName")}<span className='text-danger'>*</span></Label><br/>
                                            <Input 
                                                name="firstName" id='firstName-field' className="form-control" 
                                                placeholder={props.t("firstName_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.firstName || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.firstName && validation.errors.firstName ? true : false
                                                } 
                                            />
                                            {validation.touched.firstName && validation.errors.firstName ? (
                                                <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="lastName-field" className="form-label">{props.t("lastName")}</Label><br/>
                                            <Input 
                                                name="lastName" id='lastName-field' className="form-control" 
                                                placeholder={props.t("lastName_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.lastName || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.lastName && validation.errors.lastName ? true : false
                                                } 
                                            />
                                            {validation.touched.lastName && validation.errors.lastName ? (
                                                <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2 mt-1'>
                                            <Label htmlFor="dob-field" className="form-label">{props.t("gender")}<span className='text-danger'>*</span></Label><br/>
                                            <div className="gender-sec">
                                                <div className="gender-male-sec d-flex align-items-center">
                                                    <input type="radio" name="gender" id="male-gender" checked={selectedGender === "M" ? true: false} onChange={() => handleSelectGender("M")}/>
                                                    <label htmlFor="male-gender" className="mb-0">&nbsp;{props.t("male")}</label>
                                                </div>
                                                <div className="gender-female-sec d-flex align-items-center">
                                                    <input type="radio" name="gender" id="female-gender" checked={selectedGender === "F" ? true: false} onChange={() => handleSelectGender("F")}/>
                                                    <label htmlFor="female-gender" className="mb-0">&nbsp;{props.t("female")}</label>
                                                </div>
                                                <div className="gender-other-sec d-flex align-items-center">
                                                    <input type="radio" name="gender" id="other-gender" checked={selectedGender === "O" ? true: false} onChange={() => handleSelectGender("O")} />
                                                    <label htmlFor="other-gender" className="mb-0">&nbsp;{props.t("other")}</label>
                                                </div>
                                            </div>
                                        </div>
                                        {errorGender ?
                                            <span className='text-danger' style={{ fontSize: '13px' }}> {props.t("you_need_to_select_gender")}</span>
                                            :
                                            <></>
                                        }
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2 mt-1'>
                                            <Label htmlFor="dob-field" className="form-label">{props.t("dob")}<span className='text-danger'>*</span></Label><br/>
                                            <Input 
                                                name="dob" id='dob-field' className="form-control" type="date"
                                                validate={{ required: { value: true } }} max={getTodayDate()} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.dob || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.dob && validation.errors.dob ? true : false
                                                } 
                                            />
                                            {validation.touched.dob && validation.errors.dob ? (
                                                <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>

                                <hr className='' />

                                <Row className='mb-3'>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="patientType-field" className="form-label">{props.t("patient_type")}<span className='text-danger'>*</span></Label><br/>
                                            <Select
                                                name='patientType'
                                                id='patientType-field'
                                                value={selectedType}
                                                isMulti={false}
                                                isClearable={false}
                                                onBlur={validation.handleBlur}
                                                onChange={(e) => {
                                                    handleSelectType(e);
                                                }}
                                                options={typeOptions}
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                            />
                                            {errorType ?
                                                <span className='text-danger' style={{ fontSize: '13px' }}> {props.t("you_need_to_select_type")}</span>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2 mt-1'>
                                            <Label htmlFor="phone-field" className="form-label">{props.t("phoneNumber")}<span className='text-danger'>*</span></Label><br/>
                                            <Input 
                                                name="phone" id='phone-field' className="form-control" 
                                                placeholder={props.t("phoneNumber_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.phone || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.phone && validation.errors.phone ? true : false
                                                } 
                                            />
                                            {validation.touched.phone && validation.errors.phone ? (
                                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2 mt-1'>
                                            <Label htmlFor="email-field" className="form-label">{props.t("email")}</Label><br/>
                                            <Input 
                                                name="email" id='email-field' className="form-control" 
                                                placeholder={props.t("email_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.email || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.email && validation.errors.email ? true : false
                                                } 
                                            />
                                            {validation.touched.email && validation.errors.email ? (
                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="profession-field" className="form-label">{props.t("profession")}</Label><br/>
                                            <Input
                                                name="profession" id='profession-field' className="form-control"
                                                placeholder={props.t("profession_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.profession || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.profession && validation.errors.profession ? true : false
                                                }
                                            />
                                            {validation.touched.profession && validation.errors.profession ? (
                                                <FormFeedback type="invalid">{validation.errors.profession}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <hr className='' />
                                <Row>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="address-field" className="form-label">{props.t("address")}<span className='text-danger'>*</span></Label><br/>
                                            <Input 
                                                name="address" id='address-field' className="form-control" 
                                                placeholder={props.t("address_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.address || ""}
                                                autoComplete="new-address"
                                                invalid={
                                                    validation.touched.address && validation.errors.address ? true : false
                                                } 
                                            />
                                            {validation.touched.address && validation.errors.address ? (
                                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="city-field" className="form-label">{props.t("city")}</Label><br/>
                                            <Input 
                                                name="city" id='city-field' className="form-control" 
                                                placeholder={props.t("city_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.city || ""}
                                                autoComplete="new-city"
                                                invalid={
                                                    validation.touched.city && validation.errors.city ? true : false
                                                } 
                                            />
                                            {validation.touched.city && validation.errors.city ? (
                                                <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="region-field" className="form-label">{props.t("region")}</Label><br/>
                                            <Select
                                            name='patientType'
                                            id='patientType-field'
                                            className='p-0'
                                            value={selectedRegion}
                                            isMulti={false}
                                            isClearable={false}
                                            autoComplete="off"
                                            onBlur={validation.handleBlur}
                                            onChange={(e) => {
                                                handleSelectRegion(e);
                                            }}
                                            options={regionListe}
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                
                                <hr className='mt-3' />
                                <h6 className='' style={{marginTop:"25px", marginBottom:"25px"}}>{props.t("personToContact")}</h6>
                                <Row>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="contact_fullName-field" className="form-label">{props.t("fullName")}<span className='text-danger'>*</span></Label><br/>
                                            <Input 
                                                name="contact_fullName" id='region-field' className="form-control" 
                                                placeholder={props.t("fullname_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                autoComplete="off"
                                                onBlur={validation.handleBlur} value={validation.values.contact_fullName || ""}
                                                invalid={
                                                    validation.touched.contact_fullName && validation.errors.contact_fullName ? true : false
                                                } 
                                            />
                                            {validation.touched.contact_fullName && validation.errors.contact_fullName ? (
                                                <FormFeedback type="invalid">{validation.errors.contact_fullName}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="contact_phoneNumber-field" className="form-label">{props.t("phoneNumber")}<span className='text-danger'>*</span></Label><br/>
                                            <Input 
                                                name="contact_phoneNumber" id='contact_phoneNumber-field' className="form-control" 
                                                placeholder={props.t("phoneNumber_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                autoComplete="off"
                                                onBlur={validation.handleBlur} value={validation.values.contact_phoneNumber || ""}
                                                invalid={
                                                    validation.touched.contact_phoneNumber && validation.errors.contact_phoneNumber ? true : false
                                                } 
                                            />
                                            {validation.touched.contact_phoneNumber && validation.errors.contact_phoneNumber ? (
                                                <FormFeedback type="invalid">{validation.errors.contact_phoneNumber}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="contact_email-field" className="form-label">{props.t("email")}</Label><br/>
                                            <Input 
                                                name="contact_email" id='contact_email-field' className="form-control" 
                                                placeholder={props.t("email_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.contact_email || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.contact_email && validation.errors.contact_email ? true : false
                                                } 
                                            />
                                            {validation.touched.contact_email && validation.errors.contact_email ? (
                                                <FormFeedback type="invalid">{validation.errors.contact_email}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="relationship-field" className="form-label">{props.t("relationship")}<span className='text-danger'>*</span></Label><br/>
                                            <Input 
                                                name="relationship" id='relationship-field' className="form-control" 
                                                placeholder={props.t("relationship_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.relationship || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.relationship && validation.errors.relationship ? true : false
                                                } 
                                            />
                                            {validation.touched.relationship && validation.errors.relationship ? (
                                                <FormFeedback type="invalid">{validation.errors.relationship}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xl={4} sm={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor="contact_profession-field" className="form-label">{props.t("profession")}</Label><br/>
                                            <Input 
                                                name="contact_profession" id='contact_profession-field' className="form-control" 
                                                placeholder={props.t("profession_placeholder")} type="text"
                                                validate={{ required: { value: true } }} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.contact_profession || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.contact_profession && validation.errors.contact_profession ? true : false
                                                } 
                                            />
                                            {validation.touched.contact_profession && validation.errors.contact_profession ? (
                                                <FormFeedback type="invalid">{validation.errors.contact_profession}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>

                                <div className="btnn mt-5">
                                    <div className="">
                                        <Link to={ isEdit ? `/patients/details/${currentPatient && currentPatient.id}` : "/patients"} className="btn save-del btn-danger px-5">{props.t("cancel")}</Link>
                                    </div>
                                    <div className="btn-two">
                                        <button type="submit" onClick={() => {setSaveNext(true); checkInput() }} className="btn save-n-next-btn px-5">{ isEdit ? props.t("update") :  props.t("save_and_next")}</button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PatientRegistration))