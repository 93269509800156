import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useReactToPrint } from 'react-to-print';

import withRouter from '../../../Common/withRouter';
import { getPrintSetting as onGetPrintSetting } from '../../../../slice/thunks';
import GeneralCashierContent from './Content/Cashier/GeneralCashierContent';
import MatrixCashierContent from './Content/Cashier/MatrixCashierContent';
import ThermalCashierContent from './Content/Cashier/ThermalCashierContent';
import GeneralDispensaryContent from './Content/Dispensary/GeneralDispensaryContent';
import MatrixDispensaryContent from './Content/Dispensary/MatrixDispensaryContent';
import ThermalDispensaryContent from './Content/Dispensary/ThermalDispensaryContent';
import GeneralLabReportsContent from './Content/LabReports/GeneralLabReportsContent';
import MatrixLabReportsContent from './Content/LabReports/MatrixLabReportsContent';
import ThermalLabReportsContent from './Content/LabReports/ThermalLabReportsContent';
import { withTranslation } from 'react-i18next';

const COMPONENT_MAP = {
  cashier: {
    General: GeneralCashierContent,
    DotMatrix: MatrixCashierContent,
    Thermal: ThermalCashierContent,
  },
  dispensary: {
    General: GeneralDispensaryContent,
    DotMatrix: MatrixDispensaryContent,
    Thermal: ThermalDispensaryContent,
  },
  lab: {
    General: GeneralLabReportsContent,
    DotMatrix: MatrixLabReportsContent,
    Thermal: ThermalLabReportsContent,
  },
};

function PrintInvoiceComponent(props) {
  
  const { module } = props;
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [receiptType, setReceiptType] = useState(null);

  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) => ({
      printSettings: setting.printSettings,
      error: setting.error,
    })
  );
  const { printSettings } = useSelector(SettingsProperties);

  useEffect(() => {
    loadPrintSettings();
  }, [dispatch]);

  useEffect(() =>{
    if (printSettings && printSettings.printConfigs) 
      setReceiptType(module === "lab" ? printSettings.printConfigs['labReports'] : printSettings.printConfigs[`${module}Receipt`]);
  },[printSettings])

  const printFn = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Veronica-Invoice',
    copyStyles: true,
  });

  const handlePrint = useCallback(() => {
    printFn();
  }, [printFn]);

  const loadPrintSettings = useCallback(() => {
    dispatch(onGetPrintSetting());
  });



  const renderContent = () => {
    if (!printSettings || !printSettings.printConfigs || !receiptType) return null;
    const ContentComponent = COMPONENT_MAP[module]?.[receiptType] || null;

    return ContentComponent ? 
        <ContentComponent 
          items={props.items} 
          sale={props.sale} 
          section={props.section}
          totalAmount={props.totalAmount} 
          amountRecieved={props.amountRecieved} 
        /> 
      : null;
  };

  return (
    <Modal
      id='showModal'
      isOpen={props.isOpen || false}
      toggle={props.toggleCashier}
      centered
      className={`modal-dialog modal-dialog-centered invoice ${
        receiptType === "General" ? "modal-xl" :
        receiptType === "DotMatrix" ? "modal-lg" :
        receiptType === "Thermal" ? "modal-sm therm-lab-rep-modal" : ""
      }`}
    >

      <ModalHeader className="bg-light p-3" toggle={props.toggleCashier} />
      <ModalBody className="modal-body">
          <div ref={componentRef} style={{ width: " 835px", height: "417px" }}>
            {renderContent()}
          </div>
          <div className="col-sm-auto p3">
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-secondary" onClick={props.toggleCashier}>
                {props.t('close')}
              </button>
              <button type="submit" className="btn btn-primary" onClick={handlePrint}>
                {props.t('print')}
              </button>
            </div>
          </div>
      </ModalBody>
    </Modal>
  );
}

export default withRouter(withTranslation()(PrintInvoiceComponent))