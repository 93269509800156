import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation  } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import withRouter from "../components/Common/withRouter"
import { useDispatch } from "react-redux"
import {
  logoutUser,
} from '../slice/thunks'


const  Sidebar = (props) => {

  const location = useLocation()
  const dispatch = useDispatch()
  const [currentSelectedSideBar, setCurrentSelectedSideBar] = useState(null)


  const [currentSideBar, setCurrentSideBar] = useState([
    { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
  ])


  const sideBarUser = useMemo(() => [
      { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
      { link: "/patients", label: "output_patient", icon: "fa fa-layer-group me-2", children: null},
      { link: "/lab/lab-record", label: "lab_record", icon: "fa fa-layer-group me-2", children: null},
      { link: "/admit-patient", label: "ipd", icon: "fa fa-layer-group me-2", children: null},
      { link: "/cashier", label: "cashier", icon: "fa fa-layer-group me-2",
        children: [
          { link: "/cashier/all-sales", label: "all_sales", icon: "", children: null},
          { link: "/cashier/requisitions", label: "expenditors", icon: "", children: null},
          { link: "/cashier/cashdrop", label: "cash_drop", icon: "", children: null},
          { link: "/cashier/cash-journal", label: "cash_journal", icon: "", children: null},
          { link: "/cashier/other-revenue", label: "other_revenue", icon: "", children: null},
          { link: "/cashier/patients-checkout", label: "patients_checkout", icon: "", children: null},
          { link: "/cashier/account-summary", label: "account_summary", icon: "", children: null},
        ]
      },
      { link: "/accounting", label: "accounting", icon: "fa fa-layer-group me-2", children:
        [
          { link: "/accounting/banking", label: "bankings", icon: "", children: null},
          { link: "/accounting/budgeting", label: "budgeting", icon: "", children: null},
          { link: "/accounting/requisitions", label: "requisitions", icon: "", children: null},
        ]
      },
      { link: "/pharmacy", label: "pharmacy", icon: "fa fa-layer-group me-2",
        children: [
          { link: "/pharmacy/invoices-list", label: "invoices", icon: "", children: null},
          { link: "/pharmacy/occupied_wards", label: "occupied_wards", icon: "", children: null},
          { link: "/pharmacy/transfert-order", label: "transfert_orders", icon: "", children: null},
          { link: "/pharmacy/stock-reports", label: "stock_reports", icon: "", children: null},
        ]
      },
      { link: "/warehouse", label: "warehouse", icon: "fa fa-layer-group me-2",
        children: [
          { link: "/warehouse/item-record", label: "items_record", icon: "", children: null},
          { link: "/warehouse/record-supplier", label: "record_suppliers", icon: "", children: null},
          { link: "/warehouse/purchase-supplier", label: "purchase_orders", icon: "", children: null},
          { link: "/warehouse/transfert-order", label: "transfert_orders", icon: "", children: null},
          { link: "/warehouse/stock-reports", label: "stock_reports", icon: "", children: null},
        ]
      },
      { link: "/lab", label: "Lab", icon: "fa fa-layer-group me-2",
        children: [
          { link: "/lab/lab-record", label: "lab_record", icon: "fa fa-layer-group me-2", children: null},
          { link: "/settings/samples", label: "Lab samples", icon: "", children: null},
          { link: "/settings/remarks", label: "Lab remarks", icon: "", children: null},
        ]
      },
      { link: "/profile", label: "my_profile", icon: "fa fa-layer-group me-2", children: null},
      { link: "/settings", label: "settings", icon: "fa fa-layer-group me-2", children: null},

    ],[]);

    const sideBarOPD = useMemo(() => [
      { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
      { link: "/patients", label: "output_patient", icon: "fa fa-layer-group me-2", children: null},
      { link: "/profile", label: "my_profile", icon: "fa fa-layer-group me-2", children: null},

    ],[]);

    const sideBarIPD = useMemo(() => [
      { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
      { link: "/admit-patient", label: "ipd", icon: "fa fa-layer-group me-2", children: null},
      { link: "/profile", label: "my_profile", icon: "fa fa-layer-group me-2", children: null},

    ],[]);

  const sideBarCashier =  useMemo(() => [
    { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
    { link: "/cashier", label: "cashier", icon: "fa fa-layer-group me-2",
      children: [
        { link: "/cashier/all-sales", label: "all_sales", icon: "", children: null},
        { link: "/cashier/requisitions", label: "expenditors", icon: "", children: null},
        { link: "/cashier/cashdrop", label: "cash_drop", icon: "", children: null},
        { link: "/cashier/cash-journal", label: "cash_journal", icon: "", children: null},
        { link: "/cashier/other-revenue", label: "other_revenue", icon: "", children: null},
        { link: "/cashier/patients-checkout", label: "patients_checkout", icon: "", children: null},
        { link: "/cashier/account-summary", label: "account_summary", icon: "", children: null},
      ]
    },
    { link: "/profile", label: "my_profile", icon: "fa fa-layer-group me-2", children: null},
  ],[]);

  const sideBarPharmacisit =  useMemo(() => [
    { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
    { link: "/pharmacy", label: "pharmacy", icon: "fa fa-layer-group me-2",
      children: [
        { link: "/pharmacy/invoices-list", label: "invoices", icon: "", children: null},
        { link: "/pharmacy/occupied_wards", label: "occupied_wards", icon: "", children: null},
        { link: "/pharmacy/transfert-order", label: "transfert_orders", icon: "", children: null},
        { link: "/pharmacy/stock-reports", label: "stock_reports", icon: "", children: null},
      ]
    },
    { link: "/profile", label: "my_profile", icon: "fa fa-layer-group me-2", children: null},
  ],[]);

  const sideBarUserLab =  useMemo(() => [
    { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
    { link: "/lab/lab-record", label: "lab_record", icon: "fa fa-layer-group me-2", children: null},
    { link: "/profile", label: "my_profile", icon: "fa fa-layer-group me-2", children: null},
    { link: "/settings", label: "settings", icon: "fa fa-layer-group me-2", children:
      [
        { link: "/settings/samples", label: "samples", icon: "", children: null},
        { link: "/settings/remarks", label: "remarks", icon: "", children: null},
      ]
    },
  ],[]);

  const sideBarAccountant =  useMemo(() => [
    { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
    { link: "/accounting", label: "accounting", icon: "fa fa-layer-group me-2", children:
      [
        { link: "/accounting/banking", label: "bankings", icon: "", children: null},
        { link: "/accounting/budgeting", label: "budgeting", icon: "", children: null},
        { link: "/accounting/requisitions", label: "requisitions", icon: "", children: null},
      ]
    },
    { link: "/profile", label: "my_profile", icon: "fa fa-layer-group me-2", children: null},
  ],[]);

  const sideBarDoctor =  useMemo(() => [
    { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
    { link: "/doctor/doctor-panel-see-patient", label: "doctor-panel", icon: "fa fa-layer-group me-2", children: null},
    { link: "/profile", label: "my_profile", icon: "fa fa-layer-group me-2", children: null},
  ],[]);

  useEffect(() => {
    setCurrentSideBar([
      { link: "/dashboard", label: "dashboard", icon: "fa fa-house me-2", children: null},
    ])
  },[])

  useEffect(() =>{
    if (props.currentRole !== null && props.currentPermission !== null) {
      if (props.currentRole && props.currentRole === 'DOCTOR') {
        setCurrentSideBar(sideBarDoctor)
      } else if (props.currentRole && props.currentRole === 'CASHIER') {
        setCurrentSideBar(sideBarCashier)
      }else if (props.currentRole && props.currentRole === 'PHARMACIST') {
        setCurrentSideBar(sideBarPharmacisit)
      }else if (props.currentRole && props.currentRole === 'ACCOUNTANT') {
        setCurrentSideBar(sideBarAccountant)
      } else if (props.currentRole && props.currentRole === 'LAB_TECHNICIAN') {
        setCurrentSideBar(sideBarUserLab)
      } else if (props.currentRole && props.currentRole === 'OPD') {
        setCurrentSideBar(sideBarOPD)
      } else if (props.currentRole && props.currentRole === 'IPD') {
        setCurrentSideBar(sideBarIPD)
      } else {
        setCurrentSideBar(sideBarUser)
      }
    }

  },[props.currentRole, props.currentPermission, sideBarDoctor, sideBarCashier, sideBarPharmacisit, sideBarAccountant, sideBarUserLab, sideBarUser, sideBarOPD, sideBarIPD])




  const doesRouteInclude = (routeValue) => {
    return location.pathname.includes(routeValue)
  }

  const handleLogout = () => {
    dispatch(logoutUser(props.router.navigate))
  }

  const handleOpenMenu = (row) => {
    if (currentSelectedSideBar === row.label) {
    } else {
      setCurrentSelectedSideBar(row.label)
    }
  }

  const handleOpenMenuTop = (row) => {
    if (currentSelectedSideBar === row.label) {
      setCurrentSelectedSideBar(null)
    }else {
      setCurrentSelectedSideBar(row.label)
    }
  }

  return (
    <div className="sidebar">
      <nav className="navbar">
          <div className="navbar-nav w-100">
              {currentSideBar && currentSideBar.map((row, index) => {
                    if (row.children !== null ) {
                      return <div key={index} className="accordion main-accordion" id="builder-right-full-top-btn">
                          <div className="accordion-item" onClick={() => { handleOpenMenuTop(row)}}>
                              <h4 className="accordion-header" id="headingOne">
                                  <button className={ currentSelectedSideBar === row.label ? "accordion-button nav-link" : "accordion-button collapsed nav-link"} type="button"
                                      data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                      aria-controls="collapseOne"><i className="fa fa-layer-group me-2"></i>{props.t(row.label)}
                                  </button>
                              </h4>
                              <div id="" className={ currentSelectedSideBar === row.label ? "accordion-collapse collapse show": "accordion-collapse collapse hide"} aria-labelledby="headingOne">
                                {row.children.map((subRow, subIndex) => {
                                  return <Link key={subIndex}
                                  to={subRow.link}
                                  onClick={(e) => { e.stopPropagation(); handleOpenMenu(row)}}
                                  className={ doesRouteInclude(subRow.link) ? "dropdown-item active" : "dropdown-item"}> {props.t(subRow.label)}</Link>
                                })}
                              </div>
                          </div>
                      </div>

                    } else {
                      return <Link key={index} to={row.link} className={ doesRouteInclude(row.link) ? "nav-item nav-link active" : "nav-item nav-link"} onClick={() => { handleOpenMenu(row)}}>
                          <i className={row.icon}></i>{props.t(row.label)}
                      </Link>
                    }
                })
              }
              <Link  className="nav-item nav-link" onClick={() => handleLogout()}>
                  <i className="fa fa-layer-group me-2"></i>{props.t("logout")}
              </Link>
          </div>
      </nav>
    </div>
  )
}

export default withRouter(withTranslation()(Sidebar))
